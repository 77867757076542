import React, {useEffect, useState} from "react";
import Layout from "../components/layout";
import gql from "graphql-tag";
import {Mutation} from "react-apollo";
import SEO from "../components/seo";
import {client} from '../apollo/client';

const VERIFY_NEWSLETTER_SUBSCRIPTION = gql`
    mutation VerifyNewsletterSubscription($input:VerifyNewsletterSubscriptionInput!) {
        verifyNewsletterSubscription(input: $input)
    }
`

const VerifyNewsletterSubscription = ({verifyNewsletterSubscription, location}) => {
  const [message, setMessage] = useState('We are verifying your email')

  useEffect(() => {
    const input = {token: location.search.slice(1)}
    verifyNewsletterSubscription({variables: {input}})
      .then(r => {
        if (r.data.verifyNewsletterSubscription) {
          setMessage("Your email is verified, thank you for subscribing")
        } else {
          console.error('verification error', input)
          setMessage("Something went wrong")
        }
      })
      .catch(e => console.error(e))
  }, [location, verifyNewsletterSubscription]);

  return <div className="font-sans font-light my-8 text-center">{message}</div>
}

export default ({location}) => {
  return <Layout>
    <SEO title="Email verification" keywords={[`about us`, `fashion`]}/>
    <div className="font-title text-xl uppercase my-8 text-center tracking-wide">Email verification</div>
    <Mutation
      client={client}
      mutation={VERIFY_NEWSLETTER_SUBSCRIPTION}>
      {(verifyNewsletterSubscription) => {
        return <VerifyNewsletterSubscription
          verifyNewsletterSubscription={verifyNewsletterSubscription}
          location={location}/>;
      }}
    </Mutation>
  </Layout>;
}
